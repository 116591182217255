<template>
  <div class="passport-breadcrumbs">
    <span class="route-title"> {{ this.parentRouteName }} </span>
    <Carat class="carat" />
    <span class="route-title"> {{ this.routeName }} </span>
  </div>
</template>

<script>
import Carat from '@/assets/icons/breadcrumb_carat.svg';

export default {
  name: 'PassportBreadcrumbs',
  props: {
    parentRoute: String,
    currentRoute: String,
  },
  components: {
    Carat,
  },
  computed: {
    parentRouteName() {
      return this.parentRoute.split('-').join(' ').replace('and', '&');
    },
    routeName() {
      return this.currentRoute.split('-').join(' ');
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.passport-breadcrumbs {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 10em;
  margin-bottom: 3em;
  .route-title {
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 0.6px;
  }
  .carat {
    width: 10px;
    height: 10px;
    path {
      fill: #222;
    }
  }
}
</style>

<template>
  <div class="examples">
    <PassportBreadcrumbs :parentRoute="this.$route.meta.parentComponent" :currentRoute="this.$route.name" />
    <span class="page-heading"> Create New Target </span>
    <hr class="line-break" />
    <h2 class="category-heading">Target Information</h2>
    <div class="target-info">
      <div>
        <span class="input-header"> Target Name (EN / FR)* </span>
        <PassportInput class="target-name" v-model="targetName" placeholder="Type your target name (EN)" required />
        <PassportInput class="target-name" v-model="targetNameFR" placeholder="Type your target name (FR)" required />
        <span
          :class="{
            'input-instructions': !isError || (targetName && targetNameFR),
            'input-instructions__error': isError && (!targetName || !targetNameFR),
          }"
        >
          Give this target a name.
        </span>
      </div>
      <div>
        <span class="input-header"> Group* </span>
        <PassportDropdown
          class="dropdown"
          :options="this.groupOptions"
          v-model="selectedGroup"
          trackBy="type"
          label="label"
          placeholder="Select group"
        />
        <span
          :class="{
            'input-instructions': !isError || selectedGroup,
            'input-instructions__error': isError && !selectedGroup,
          }"
        >
          Select this group in which the target belongs to.
        </span>
      </div>
    </div>
    <div class="target-description">
      <span class="input-header"> description (EN / FR)* </span>
      <PassportInput
        placeholder="Add a description to this target (EN)"
        v-model="targetDescription"
        class="description-text"
        type="textarea"
      />
      <PassportInput
        placeholder="Add a description to this target (FR)"
        v-model="targetDescriptionFR"
        class="description-text"
        type="textarea"
      />
      <span
        :class="{
          'input-instructions': !isError || (targetDescription && targetDescriptionFR),
          'input-instructions__error': isError && (!targetDescription || !targetDescriptionFR),
        }"
      >
        Add a description to this target.
      </span>
    </div>
    <div class="point-holder">
      <span class="input-header"> Point value* </span>
      <PassportInput class="points-input" v-model="points" placeholder="" />
      <span
        :class="{
          'input-instructions': !isError || points,
          'input-instructions__error': isError && !points,
        }"
      >
        Enter how many points a user will earn when they scan and complete this target.
      </span>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Target Setup</h2>
    <div class="point-holder">
      <span class="input-header"> Target Type* </span>
      <PassportDropdown class="dropdown" :options="options" v-model="selectedTarget" trackBy="type" label="label" />
      <span
        :class="{
          'input-instructions': !isError || selectedTarget,
          'input-instructions__error': isError && !selectedTarget,
        }"
      >
        Select a target type. Please note: To setup a trivia or personality quiz target, you must first create the asset
        on the Trivia & Quizzes page.
      </span>
    </div>
    <div v-if="targetType('image')" class="target-type">
      <span class="input-header"> Target {{ selectedTarget.type }}* </span>
      <div class="image-holder">
        <PassportImageUploader
          ref="imageSrc"
          class="image-upload"
          key="instance-0"
          :imageWidth="350"
          :imageHeight="200"
          :maxFileSize="10000000"
          :presignedUrlParams="{ type: 'book', item: 'cover' }"
          v-model="imageUpload"
        />
      </div>
      <span
        :class="{
          'input-instructions': !imageSrcError,
          'input-instructions__error': imageSrcError,
        }"
      >
        Upload an image for this target. Acceptable file types: PNG, JPG, JPEG. File size limit: 10MB
      </span>
    </div>
    <div v-else-if="targetType('video')" class="target-type">
      <span class="input-header"> Target {{ selectedTarget.type }}* </span>
      <div class="image-holder">
        <PassportImageUploader
          ref="videoSrc"
          class="image-upload"
          key="instance-0"
          :imageWidth="350"
          :imageHeight="200"
          :maxFileSize="30000000"
          :presignedUrlParams="{ type: 'book', item: 'cover' }"
          v-model="videoUpload"
        />
      </div>
      <span
        :class="{
          'input-instructions': !videoSrcError,
          'input-instructions__error': videoSrcError,
        }"
      >
        Upload a video for this target. Acceptable file types: MP4. File size limit: 30MB
      </span>
      <div class="thumbnail-uploader">
        <p class="input-header">Target WEBM</p>
        <div class="image-holder">
          <PassportImageUploader
            ref="videoSrc2"
            class="image-upload"
            key="instance-3"
            :imageWidth="350"
            :imageHeight="200"
            :maxFileSize="30000000"
            :presignedUrlParams="{ type: 'book', item: 'cover' }"
            v-model="video2Upload"
          />
        </div>
        <span
          :class="{
            'input-instructions': !videoThumbError,
            'input-instructions__error': videoThumbError,
          }"
        >
          Upload a video for this target. Acceptable file types: WEBM. File size limit: 30MB
        </span>
      </div>
      <div class="thumbnail-uploader">
        <p class="input-header">Video Thumbnail*</p>
        <div class="image-holder">
          <PassportImageUploader
            ref="videoThumbnail"
            class="image-upload"
            key="instance-0"
            :imageWidth="350"
            :imageHeight="200"
            :maxFileSize="10000000"
            :presignedUrlParams="{ type: 'book', item: 'cover' }"
            v-model="videoThumbUpload"
          />
        </div>
        <span
          :class="{
            'input-instructions': !videoThumbError,
            'input-instructions__error': videoThumbError,
          }"
        >
          Upload a thumbnail for this video. Acceptable file types: PNG, JPG, JPEG. File size limit: 10MB
        </span>
      </div>
    </div>

    <div v-else-if="targetType('link')" class="target-type">
      <div class="target-info">
        <div>
          <span class="input-header"> Target Link Title* </span>
          <PassportInput v-model="linkTitle" placeholder="Type your link title..." />
          <span
            :class="{
              'input-instructions': !linkError || linkTitle,
              'input-instructions__error': linkError && !linkTitle,
            }"
          >
            Enter your link title.
          </span>
        </div>
        <div>
          <span class="input-header"> Target Link URL* </span>
          <PassportInput v-model="linkPath" placeholder="URL" />
          <span
            :class="{
              'input-instructions': !linkError || linkPath,
              'input-instructions__error': linkError && !linkPath,
            }"
          >
            Enter URL for this target.
          </span>
        </div>
      </div>
    </div>
    <div v-else-if="targetType('quiz')" class="target-type">
      <div class="target-info">
        <div>
          <span class="input-header"> Select personality quiz * </span>
          <PassportDropdown
            class="dropdown"
            :options="this.personalityOptions"
            v-model="selectedQuiz"
            trackBy="type"
            label="label"
          />
          <span
            :class="{
              'input-instructions': !quizError || selectedQuiz,
              'input-instructions__error': quizError && !selectedQuiz,
            }"
          >
            Select which personality quiz to assign to this target.
          </span>
        </div>
      </div>
    </div>
    <div v-else-if="targetType('trivia')" class="target-type">
      <div class="target-info">
        <div>
          <span class="input-header"> Select trivia * </span>
          <PassportDropdown
            class="dropdown"
            :options="this.triviaOptions"
            v-model="selectedTrivia"
            trackBy="type"
            label="label"
          />
          <span
            :class="{
              'input-instructions': !triviaError || selectedTrivia,
              'input-instructions__error': triviaError && !selectedTrivia,
            }"
          >
            Select which trivia asset to assign to this target.
          </span>
        </div>
      </div>
    </div>
    <hr class="line-break" />
    <div class="group-type">
      <h2 class="category-heading">Stamp</h2>
      <span class="input-header"> Upload Stamp </span>
      <div class="stamp-upload">
        <PassportImageUploader
          ref="stampImage"
          class="image-upload"
          key="instance-0"
          :imageWidth="350"
          :imageHeight="200"
          :maxFileSize="10000000"
          :presignedUrlParams="{ type: 'book', item: 'cover' }"
          v-model="stamp"
        />
      </div>
      <span class="input-instructions">
        Upload the stamp the user will receive when they find this target. File must be a PNG, JPEG or JPG and be under
        10 MB in size.
      </span>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Hint (Optional)</h2>
    <div class="switch-holder">
      <span class="input-header"> Hint </span>
      <PassportSwitch
        class="switch"
        v-model="hintSwitch"
        @click="hintSwitch ? (showModal = false) : (showModal = true)"
        :label="hintSwitch ? 'Enabled' : 'Disabled'"
      />
      <span class="input-instructions">
        When enabled, you can add a hint to this stamp. Users will be able to reveal this hint before finding the target
        associated to this stamp.
      </span>
      <div v-if="hintSwitch" class="hint">
        <div class="hint-title">
          <div>
            <span class="input-header"> Hint title (EN / FR) </span>
            <PassportInput class="points-input" v-model="hint" placeholder="Here's a hint" />
            <PassportInput class="points-input" v-model="hintFR" placeholder="Voici un indice" />
            <span class="input-instructions">
              Add a title to your hint. If no title is added, the default “Here’s a hint” will be used.
            </span>
            <div class="hint-description">
              <span class="input-header"> Hint description (EN / FR)* </span>
              <PassportInput
                class="points-input"
                v-model="hintDescription"
                type="textarea"
                placeholder="Add a description to this hint (EN)"
              />
              <PassportInput
                class="points-input"
                v-model="hintDescriptionFR"
                type="textarea"
                placeholder="Add a description to this hint (FR)"
              />
              <span
                :class="{
                  'input-instructions': !hintError || (hintDescription && hintDescriptionFR),
                  'input-instructions__error': hintError && (!hintDescription || !hintDescriptionFR),
                }"
              >
                Add a description to this hint. This description should help a user to find this target.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">QR Code</h2>
    <div class="qr-holder">
      <span class="input-header"> QR code </span>
      <div @click="downloadQRCode" class="download-button">
        <span> Download </span>
        <DownloadIcon class="download-icon" />
      </div>
      <span class="input-instructions">
        Your QR code will generate after this target is saved. After it has been generated, you can download the QR code
        here or from the Manage Targets page.
      </span>
    </div>
    <hr class="line-break" />
    <h2 class="category-heading">Stamp</h2>
    <div class="switch-holder">
      <span class="input-header"> Status </span>
      <PassportSwitch class="switch" v-model="draft" :label="draft ? 'Published' : 'Draft'" />
      <span class="input-instructions"> Targets must be published to appear in a group. </span>
    </div>
    <hr class="line-break" />
    <div class="buttons-container">
      <PassportButton label="Save Target" @click="errorValidation" />
      <PassportButton variant="text secondary" to="/targets" label="Cancel" />
    </div>
    <PassportModal disableClosingOnMask v-model="showDraftModal">
      <div class="section">
        <!-- <div class="icon-container">
          <DraftIcon class="icon" />
        </div> -->
        <span class="modal-heading">Target Still Draft</span>
        <hr class="modal-separator" />
        <div class="choice-text">
          <span> Please note: This target is still a draft and will not appear on the platform until published. </span>
        </div>
        <div class="button-container">
          <PassportButton label="okay" class="okay-button" @click="showDraftModal = false" />
          <PassportButton
            variant="text"
            label="Do not show this warning again"
            class="warning-button"
            @click="warning"
          />
        </div>
      </div>
    </PassportModal>
  </div>
</template>

<script>
import PassportButton from '@/components/PassportButton.vue';
import PassportSwitch from '@/components/PassportSwitch.vue';
import { v4 as uuidv4 } from 'uuid';
import { ApiService } from '@/services/api.service';
import QRCodeStyling from 'qr-code-styling';
import PassportInput from '@/components/PassportInput.vue';
import PassportDropdown from '@/components/PassportDropdown.vue';
import DownloadIcon from '@/assets/icons/download_icon.svg';
import PassportImageUploader from '@/components/PassportImageUploader.vue';
import PassportBreadcrumbs from '@/components/PassportBreadcrumbs.vue';
import PassportModal from '@/components/PassportModal.vue';
// import DraftIcon from '@/assets/icons/target_icon.svg';

export default {
  name: 'default',
  components: {
    PassportInput,
    PassportDropdown,
    PassportSwitch,
    DownloadIcon,
    PassportButton,
    PassportImageUploader,
    PassportBreadcrumbs,
    PassportModal,
    // DraftIcon,
  },
  props: {},
  data() {
    return {
      associatedId: '',
      id: uuidv4(),
      qrURL: '',
      isError: false,
      linkError: false,
      quizError: false,
      triviaError: false,
      hintError: false,
      // stampError: false,
      imageSrcError: false,
      videoSrcError: false,
      videoThumbError: false,
      targetName: '',
      targetNameFR: '',
      targetDescription: '',
      targetDescriptionFR: '',
      assetDescription: '',
      assetDescriptionFR: '',
      points: '',
      selectedTarget: '',
      selectedGroup: '',
      selectedQuiz: '',
      selectedTrivia: '',
      hint: '',
      hintFR: '',
      hintDescription: '',
      hintDescriptionFR: '',
      linkTitle: '',
      linkPath: '',
      stamp: '',
      imageUpload: '',
      videoUpload: '',
      video2Upload: '',
      videoThumbUpload: '',
      hintSwitch: false,
      draft: false,
      stampPresigned: '',
      imagePresigned: '',
      videoPresigned: '',
      videoThumbPresigned: '',
      assetSrc: '',
      assetSrc2: '',
      iconSrc: '',
      showDraftModal: false,
      dontShow: this.$store.state.dontShow,
      assetThumbnailSrc: null,
      options: [
        {
          label: 'Text only',
          type: 'text',
        },
        {
          label: 'Image',
          type: 'image',
        },
        {
          label: 'Video',
          type: 'video',
        },
        {
          label: 'Link',
          type: 'link',
        },
        {
          label: 'Personality Quiz',
          type: 'quiz',
        },
        {
          label: 'Trivia',
          type: 'trivia',
        },
      ],
      trivias: [
        {
          label: 'Trivia 1',
          type: 'trivia1',
        },
        {
          label: 'Trivia 2',
          type: 'trivia2',
        },
        {
          label: 'Trivia 3',
          type: 'trivia3',
        },
      ],
    };
  },
  created() {},
  computed: {
    groupOptions() {
      return this.$store.state.groupInfo.map((group) => ({
        label: group.name,
        value: group.id,
      }));
    },
    personalityOptions() {
      return this.$store.state.personalityInfo.map((quiz) => ({
        label: quiz.name,
        value: quiz.id,
      }));
    },
    triviaOptions() {
      return this.$store.state.triviaInfo.map((quiz) => ({
        label: quiz.name,
        value: quiz.id,
      }));
    },
  },
  watch: {},
  mounted() {},
  methods: {
    downloadQRCode() {
      this.qrURL = `${process.env.VUE_APP_URL}/target/${this.id}`;
      const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        type: 'png',
        data: `${this.qrURL}`,
        backgroundOptions: {
          color: '#ffffff',
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 20,
        },
      });
      qrCode.download({ name: `target-${this.id}`, extension: 'png' });
    },
    warning() {
      this.$store.commit('setDontShow', true);
      this.showDraftModal = false;
    },
    targetType(type) {
      if (!this.selectedTarget?.type) return false;

      return this.selectedTarget.type === type;
    },
    async errorValidation() {
      this.isError = true;
      if (this.selectedTarget.type === 'quiz') {
        this.associatedId = this.selectedQuiz.value;
      } else if (this.selectedTarget.type === 'trivia') {
        this.associatedId = this.selectedTrivia.value;
      } else {
        this.associatedId = null;
      }
      if (this.selectedTarget.type === 'text') {
        this.assetDescription = this.targetDescription;
        this.assetDescriptionFR = this.targetDescriptionFR;
      }
      // if (!this.$refs.stampImage.hasFile) {
      //   this.stampError = true;
      // } else {
      //   this.stamp = this.$refs.stampImage.file.extension;
      //   this.stampError = false;
      // }
      if (this.selectedTarget.type === 'image' && !this.$refs.imageSrc.hasFile) {
        this.imageSrcError = true;
      } else if (this.selectedTarget.type === 'video') {
        if (!this.$refs.videoThumbnail.hasFile) {
          this.videoThumbError = true;
        } else {
          this.videoThumbError = false;
        }
        if (!this.$refs.videoSrc.hasFile) {
          this.videoSrcError = true;
        } else {
          this.videoSrcError = false;
        }
      } else if (this.selectedTarget.type === 'link' && !this.linkTitle && !this.linkPath) {
        this.linkError = true;
      } else if (this.selectedTarget.type === 'quiz' && !this.selectedQuiz) {
        this.quizError = true;
      } else if (this.selectedTarget.type === 'trivia' && !this.selectedTrivia) {
        this.triviaError = true;
      } else if (!this.hint && this.hintSwitch) {
        this.hintError = true;
      }
      if (
        this.targetName &&
        this.targetNameFR &&
        this.selectedGroup.value &&
        this.targetDescription &&
        this.targetDescriptionFR &&
        this.points &&
        this.selectedTarget.type // change logic
      ) {
        this.isError = false;
        this.hintError = false;
      }
      if (
        this.isError ||
        this.imageSrcError ||
        this.videoSrcError ||
        this.videoThumbError ||
        this.linkError ||
        this.quizError ||
        this.triviaError ||
        this.hintError
      ) {
        this.$notify({
          group: 'primary',
          type: 'error',
          text: 'Please fix the highlighted errors and try to save again.',
        });
      } else {
        if (this.draft === false && this.dontShow === false) {
          this.showDraftModal = true;
        }
        if (this.$refs.stampImage.hasFile) {
          this.stamp = this.$refs.stampImage.file.extension;
          if (this.stamp === 'jpeg') {
            this.stamp = 'jpg';
          }
          try {
            const { data } = await ApiService.post(`/target/${this.id}/asset`, {
              fileFormat: this.stamp,
              assetType: 'icon',
            });
            this.iconSrc = data.assetUrl;
            await this.$refs.stampImage.postS3(data.presignedUrl);
          } catch (err) {
            console.error(err);
          }
        }

        if (this.selectedTarget.type === 'image') {
          if (this.$refs.imageSrc.hasFile) {
            this.imageUpload = this.$refs.imageSrc.file.extension;
            if (this.imageUpload === 'jpeg') {
              this.imageUpload = 'jpg';
            }
            try {
              const { data } = await ApiService.post(`/target/${this.id}/asset`, {
                fileFormat: this.$refs.imageSrc.file.extension,
                assetType: 'asset',
              });
              this.assetSrc = data.assetUrl;
              await this.$refs.imageSrc.postS3(data.presignedUrl);
            } catch (err) {
              console.error(err);
            }
          }
        } else if (this.selectedTarget.type === 'video') {
          if (this.$refs.videoSrc.hasFile) {
            this.videoUpload = this.$refs.videoSrc.file.extension;
            if (this.videoUpload === 'jpeg') {
              this.videoUpload = 'jpg';
            }
            try {
              const { data } = await ApiService.post(`/target/${this.id}/asset`, {
                fileFormat: this.$refs.videoSrc.file.extension,
                assetType: 'asset',
              });
              this.assetSrc = data.assetUrl;
              await this.$refs.videoSrc.postS3(data.presignedUrl);
            } catch (err) {
              console.error(err);
            }
          }
          if (this.$refs.videoSrc2.hasFile) {
            this.video2Upload = this.$refs.videoSrc2.file.extension;
            try {
              const { data } = await ApiService.post(`/target/${this.id}/asset`, {
                fileFormat: this.$refs.videoSrc2.file.extension,
                assetType: 'asset',
              });
              this.assetSrc2 = data.assetUrl;
              await this.$refs.videoSrc2.postS3(data.presignedUrl);
            } catch (err) {
              console.error(err);
            }
          }
          if (this.$refs.videoThumbnail.hasFile) {
            this.videoThumbUpload = this.$refs.videoThumbnail.file.extension;
            if (this.videoThumbUpload === 'jpeg') {
              this.videoThumbUpload = 'jpg';
            }
            try {
              const { data } = await ApiService.post(`/target/${this.id}/asset`, {
                fileFormat: this.$refs.videoThumbnail.file.extension,
                assetType: 'thumbnail',
              });
              this.assetThumbnailSrc = data.assetUrl;
              await this.$refs.videoThumbnail.postS3(data.presignedUrl);
            } catch (err) {
              console.error(err);
            }
          }
        } else if (this.selectedTarget.type === 'link') {
          this.assetSrc = this.linkPath;
        }

        // figure out the logic for links, images, videos to post the images to the s3 bucket
        const target = {
          name: this.targetName,
          nameFR: this.targetNameFR,
          description: this.targetDescription,
          descriptionFR: this.targetDescriptionFR,
          hintTitle: this.hint || undefined,
          hintTitleFR: this.hintFR || undefined,
          hintText: this.hintDescription || undefined,
          hintTextFR: this.hintDescriptionFR || undefined,
          order: 1,
          points: this.points,
          iconSrc: this.iconSrc || null,
          targetGroupId: this.selectedGroup.value,
          draft: !this.draft,
          targetAsset: {
            associatedId: this.associatedId || undefined,
            assetType: this.selectedTarget.type,
            assetSrc: this.assetSrc || undefined,
            assetSrc2: this.assetSrc2 || undefined,
            assetDescription: this.assetDescription || undefined,
            assetDescriptionFR: this.assetDescriptionFR || undefined,
            thumbnailSrc: this.assetThumbnailSrc || undefined,
          },
        };
        // ApiService.on(422, () => {
        //   this.stampError = true;
        //   this.$notify({
        //     group: 'primary',
        //     type: 'error',
        //     text: 'Please fix the highlighted errors and try to save again.',
        //   });
        // });
        await ApiService.put(`/target/${this.id}`, target).then((res) => {
          if (res.status >= 200 && res.status < 300) {
            this.$notify({
              group: 'primary',
              type: 'success',
              text: 'Target successfully updated.',
            });
            this.$router.push('/targets');
          } else {
            this.$notify({
              group: 'primary',
              type: 'error',
              text: 'Something went wrong. Please try again.',
            });
          }
        });
        // console.log(this.iconSrc);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.examples {
  ::v-deep .passport-breadcrumbs {
    width: 17em;
  }
  ::v-deep .g-input .input-element:not([type='submit'], [type='range']) {
    height: 52px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
  .target-name {
    width: 27em;
  }
  .line-break {
    width: 1601px;
    margin-top: 3em;
    margin-bottom: 3em;
    opacity: 0.1;
  }
  .page-heading {
    display: flex;
    font-size: 28px;
  }
  .example {
    padding: 10px;
    margin-bottom: 10px;
    border: 2px solid white;
    border-radius: 10px;

    ::v-deep {
      .header {
        padding: 10px;
      }
      .collapse-wrapper > .content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
.category-heading {
  margin-bottom: 2em;
  font-size: 24px;
  letter-spacing: -0.6px;
}

.target-info,
.hint-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width:90%;
}
.input-field,
.dropdown {
  width: 28em;
  height: 52px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  &__error {
    width: 28em;
    height: 52px;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }
}
.input-header {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.6px;
}
.input-instructions {
  font-size: 14px;
  &__error {
    font-size: 14px;
    color: $red;
  }
}
.target-description,
.hint-description,
.point-holder,
.switch-holder,
.qr-code {
  margin-top: 2em;
}
.points-input {
  width: 200px;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}
.switch {
  margin-top: 1em;
  margin-bottom: 1em;
}
::v-deep .g-checkbox .label.after {
  margin-left: 20px;
}
.download-button {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100px;
  margin-top: 1em;
  margin-bottom: 1em;
  // color: rgb(93, 93, 93);
}
.download-icon {
  width: 19px;
  height: 20px;
  opacity: 0.5;
  // stroke: rgb(93, 93, 93);
  g {
    fill: #222;
  }
}
.stamp-upload {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 515px;
  margin-top: 1em;
  margin-bottom: 1.5em;
}
::v-deep .passport-button.default.primary {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-transform: uppercase;
}
.buttons-container {
  display: flex;
}
.target-type,
.hint {
  margin-top: 2em;
}
.image-holder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 515px;
  margin-top: 0.5em;
  margin-bottom: 1em;
}
.thumbnail-uploader {
  margin-top: 3em;
}
::v-deep .base-file-upload .select-file {
  align-items: center;
}
.section {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 600px;
  padding: 90px;
  // background-image: url(../assets/images/bg_modal.png);
  // background-repeat: no-repeat;
  // background-size: cover;
  background-color: #f8ecd9;
  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    height: 100px;
    margin-bottom: 40px;
    background: $light-opacity;
    border-radius: 50%;
    svg {
      width: 40px;
      height: auto;
      fill: #fff;
    }
  }
  .modal-heading {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    font-size: 34px;
  }
  .modal-separator {
    width: 200px;
    margin-bottom: 2em;
  }
  .okay-button,
  .warning-button {
    margin-top: 1em;
  }
  .choice-text {
    font-size: 16px;
    text-align: center;
  }
  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 1em;
  }
}
</style>
